@import "../../../Utils/colors.scss";

.main-container-wrapper {
  display: flex;
  justify-content: center;
}
.form-wrapper {
  display: flex;
  flex-direction: column;

  .modal-header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    text-align: center;
    color: #000000;
    padding: 1rem;
  }
  .content-body {
    padding: 1rem;
  }
  .button-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: space-between;
    justify-content: center;
    .cancel-btn {
      margin-left: 10px;
    }
  }
}
.main-container {
  width: calc(100% - 2rem) !important;
  padding: 0 !important;
  margin-left: 1.8125rem !important;
  margin-top: 1.8125rem !important;
  margin-right: 1.8125rem !important;
  background: $color-white;
  box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.25);
}

.create-cohort-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  color: $color-black;
}

.cohort-input-fields {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background: $application-header-color;
  flex-wrap: wrap;

  .mandatory {
    color: $color-red;
    vertical-align: middle;
  }

  .char-length {
    align-self: flex-end;
  }

  .cohort-title-overview-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 1.25rem;

    > span {
      padding-bottom: 0.3125rem;
    }

    .outline-root {
      color: $color-dark-grey;
      width: inherit;
      height: 7.5rem;
      background: $form-desc-root-color;
      &.title {
        height: 3.125rem;
        margin-bottom: 1.625rem;
        background: unset;
        border: unset;
        background: $form-desc-root-color;
      }
      .notchedOutline {
        border: none !important;
      }
      .input-root {
        height: 100% !important;
        border: none !important;
      }
    }
  }

  .cohort-input-fields-labels {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    .label-progress-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .label-element {
        display: flex;
      }
      .progress-element {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 70%;
        > span {
          padding-left: 10px;
          padding-right: 10px;
          width: 100%;
        }
      }
    }
    .content-wrapper {
      .title-desc-wrapper {
        width: 100% !important;
      }
    }
    .form-control-root {
      margin-left: 0;
      height: 3.125rem;
      min-width: 19.375rem;
      background: $color-white;
      margin-right: 0.75rem;
      .select-root {
        height: 100%;
        background: $color-white;
      }
    }

    .date-picker-comp {
      margin-left: 0;
      width: 19.375rem;
      height: 3.125rem;
      background: $color-white;
      // margin-right: 0.75rem;
      border-radius: 0.1875rem;

      // .input-style-override {
      //     height: inherit;
      //     border-radius: 0.1875rem;
      // }
      .notched-override {
        border: none;
        border-radius: 0.1875rem;
      }
    }
    .certification-section{
      display: flex;
      flex-direction: column;
      padding: 1.0313rem 0rem;
      .overview-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        .label{
          margin-right: 1rem;
        }
      }
      .skills-auto-box {
        > div {
          border-radius: 8px;
          color: #44444f;
          background: #ffffff;
    
        }
      }
    }
    .course-timeline-wrapper {
      display: flex;
      flex-direction: column;
      height: 5rem;
      justify-content: space-around;
      padding: 1.0313rem 0rem;
      
      .mandatory {
        color: $color-red;
        vertical-align: middle;
      }
      .radio-group-wrapper {
        display: flex;
        flex-direction: row;
      }
    }
  }
}

.cohort-video-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  background: #e2e7ee;
}

.separator {
  margin: 1.875rem;
  border: 0.0625rem solid $separator-border-color;
}

.course-details-wrapper {
  display: flex;
  width: calc(100% - 3rem);
  flex-wrap: wrap;
  margin: 1.5rem;
  .course-details-header {
    // font-family: 'Lato';
    // font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    // color: #142297;
    // text-transform: uppercase;
    width: 100%;
    // padding: 0 1.875rem;
  }

  .title-desc-comp-container {
    display: flex;
    width: 100%;
    margin-top: 1.125rem;
  }

  // not working

  // .content-wrapper {
  //     display: flex;
  //     width: 100%;
  //     margin-top: 1.125rem;

  //     .title-desc-wrapper {
  //         display: flex;
  //         flex-direction: column;
  //         width: 100%;
  //         margin-right: 3.125rem;
  //         > span {
  //             font-family: 'Inter';
  //             font-style: normal;
  //             font-weight: 400;
  //             font-size: 1rem;
  //             line-height: 1.1875rem;
  //             color: #333333;
  //         }
  //         > input {
  //             height: 2.4375rem;
  //             margin-bottom: 1.625rem;
  //             background: unset;
  //             border: unset;
  //             background: #F7F7F7;
  //             border-radius: 0.1875rem 0.1875rem 0rem 0rem;
  //         }
  //         > textarea {
  //             height: 16.125rem;
  //         }
  //     }

  // }
}

.create-cohort-footer {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  // > button {
  //     margin: 0.75rem;
  //     width: 220px;
  //     height: 48.12px;
  //     font-family: 'Lato';
  //     font-style: normal;
  //     font-weight: 700;
  //     font-size: 17px;
  //     line-height: 24px;
  //     letter-spacing: -0.22px;
  //     color: #FFFFFF;
  // }
  .footer-button-cancel {
    // border: 1px solid #0E1555 !important;
    // border-radius: 4px !important;
    background: $color-white;
    color: $color-primary-blue;
    box-shadow: 0.0625rem 0.125rem 0.125rem 0.125rem $color-light-grey;
    border-radius: 0.625rem;
    height: 3.125rem;
    width: 6.25rem;
    margin-right: 1.25rem;
    &:hover {
      background: $color-white;
    }
  }
  .footer-button-save {
    background: $color-primary-blue;
    box-shadow: 0.0625rem 0.125rem 0.125rem 0.125rem $color-light-grey;
    border-radius: 0.625rem;
    height: 3.125rem;
    width: 6.25rem;
  }
}

@media only screen and (max-width: 1000px) {
  .cohort-input-fields {
    .cohort-input-fields-labels {
      width: 100%;
    }
    .date-picker-comp {
      width: 100% !important;
    }
    .form-control-root {
      margin-left: 0 !important;
      margin-right: 0 !important;
      > div {
        width: 100%;
      }
    }
  }
}
