.modal-container-wrapper {
  display: flex;
  flex-direction: column;
  height: 32vh;
  justify-content: space-around;
  .modal-header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
  }
  .content-body {
    display: flex;
    justify-content: center;
    .text-field {
      display: flex;
      height: 46px;
      background: #f7f7f7;
      border: 1px solid #ebebeb;
      border-radius: 3px;
      width: 90%;
      justify-content: center;
    }
  }
  .next-module-action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem;
    .cancel-btn {
      background: #a8a8a8;
      border-radius: 6px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      height: 40px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;
      width: 30%;
      color: #ffffff;
    }
    .next-btn {
      background: #fac30f;
      border-radius: 6px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      height: 40px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;
      width: 30%;
      margin-left: 10px;
      color: #ffffff;
      cursor: pointer;
      &.disabled {
        background: #a8a8a8;
        border-radius: 6px;
        font-family: "Lato";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        width: 30%;
        color: #ffffff;
        cursor: not-allowed;
      }
    }
  }
  .weightage {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 250px;
    justify-content: space-around;
    .weightage-header {
      display: flex;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */

      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #000000;
    }
    .form-control {
      display: flex;
      width: 30%;
      margin: 1rem;

      .text-field {
        display: flex;
        // width: 30%;
        background: #f7f7f7;
        border: 1px solid #ebebeb;
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
    .credits-left {
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #03a10a;
    }
    .due-date-section {
      .option-picker {
        margin: 0.5rem;
        display: flex;
        flex-direction: row;
        font-family: "Lato";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: #000000;
      }
    }
    .next-module-action {
      display: flex;
      flex-direction: row;
      //justify-content: center;
      width: 100%;
      .cancel-btn {
        background: #a8a8a8;
        border-radius: 6px;
        font-family: "Lato";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        width: 30%;
        color: #ffffff;
      }
      .next-btn {
        background: #fac30f;
        border-radius: 6px;
        font-family: "Lato";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        width: 30%;
        margin-left: 10px;
        color: #ffffff;
        cursor: pointer;
        &.disabled {
          background: #a8a8a8;
          border-radius: 6px;
          font-family: "Lato";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          height: 40px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.01em;
          width: 30%;
          color: #ffffff;
          cursor: not-allowed;
        }
      }
    }
  }
}
