.form-wrapper {
  display: flex;
  flex-direction: column;
  .form-control {
    display: flex;
    flex-direction: column;
    height: 400px;
    width: 100%;
    justify-content: space-evenly;
    .form-label {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.25px;

      color: #121212;
    }
    .options-wrapper-row {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .div-checkbox {
        width: 160px;
        height: 56px;
        color: white;
        background: #0e1555;
        border: 2px solid #0e1555;
        border-radius: 5px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.disabled {
          color: black;
          background: white;
          border: 2px solid #0e1555;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .weightage-row {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .text-field {
        background-color: #f7f7f7;
      }
      .additional-resources-weightage {
        margin-left: 10px;
      }
      .live-session-weightage {
        margin-left: 10px;
      }
      .total-credit {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .weightageDisplay {
          color: green;
          text-align: center;
        }
      }
    }
  }
  .submit-section {
    display: flex;
    justify-content: center;
    .create-btn {
      width: 160px;
      height: 40px;
      align-items: center;
      color: white;
      background: #fac30f;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
        background: #fac30f;
      }
      &.disabled {
        background-color: rgb(224 224 224);
      }
    }
  }
}
