@import '../Utils/colors.scss';

.backto-container {
    margin-top: 2.5rem;
    padding: 1.25rem 2.5rem;
    padding-left: 0.9375rem;
    display: flex;
    align-items: center;
    color: $color-white;
    position: fixed;
    font-size: 0.875rem;
    width: 95%;
    top: 1.875rem;
    z-index: 1;
    height: 0.9375rem;
    background: url(../../Assets/Images/banner.png);
    cursor: pointer;

    svg {
        font-size: 1.5rem;
        padding-right: 0.625rem;
        font-weight: normal;
        color: $color-white;
    }

    &:hover {
        font-weight: 600;
    }
}

.module-overview-title {
    margin-top: 5rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

.module-overview-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
    margin: 0 auto;
    margin-top: 2rem;

    .overview-section {
        position: relative;
        width: 31.25rem;
        height: 12.5rem;
        box-shadow: rgba(100, 100, 111, 0.2) 0rem 0.4375rem 1.8125rem 0rem;
        margin: 1.25rem;
        border-radius: 0.625rem;

        &.active {
            cursor: pointer;
        }

        &:not(.active) {
            cursor: not-allowed;
        }

        .title {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.375rem;
            /* identical to box height */

            letter-spacing: 0.3125rem;
            text-transform: uppercase;

            color: #090909;
            padding: 1.25rem;
        }

        .desc {
            font-weight: 600;
            font-size: 2rem;
            line-height: 2.5rem;
            /* or 125% */

            letter-spacing: 0.0125rem;

            color: #090909;
            text-transform: capitalize;
            padding: 1.25rem;
            width: 50%;
        }

        .bg-image {
            position: absolute;
            width: 15.625rem;
            object-fit: contain;
            bottom: 0;
            right: 0;
            z-index: -1;
            opacity: 0.5;
        }
    }

    .overview-section-small {
        display: flex;
        flex-direction: column;
        margin: 1.25rem;
        margin-top: 0;
        width: 31.25rem;

        .overview-section {
            width: 100%;
            display: flex;
            align-items: center;
            height: 5.625rem;
            margin: 0;
            margin-top: 1.25rem;

            &.discuss-with-peers {
                background: linear-gradient(134.72deg, #00EAF8 0%, #6D42EF 101.5%);
            }

            &.additional-resources {
                background: linear-gradient(135deg, #FED992 0%, #EB5556 100%);
            }

            .title {
                font-weight: 600;
                font-size: 1.5rem;
                padding: 0 1.25rem;
                line-height: 2.5rem;
                letter-spacing: 0.0125rem;
                color: white;
                text-transform: capitalize;
                width: 100%;
            }
        }
    }
}


@media screen and (max-width: 1600px) {
    .module-overview-container {
        width: 90%;

        .overview-section {
            .desc {
                width: unset;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .module-overview-container {
        width: 100%;

        .overview-section {
            .desc {
                width: unset;
            }
        }
    }
}